<template>
  <div class="d-inline-block mr-1">
    <b-badge pill variant="light-dark" :id="component_id">
      <span v-for="(subWords) in word.subWords" :key="`${subWords}`">{{ subWords.wordRef }}</span>
    </b-badge>
    <b-popover :target="component_id" triggers="click" placement="bottomleft">

      <div class="phone-hint p-2">
        <!-- phones -->
        <div>
          <table class="hint-table">
            <thead>
              <tr>
                <th>Word Ref</th>
								<th>Score</th>
								<th>wordHyp</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(subWords, index) in word.subWords" :key="index">
                <th>
                  <h4 class="mr-3">{{ subWords.wordRef }}</h4>
                </th>
                <td style="color: rgb(221, 56, 29);">{{ subWords.wordScore.toFixed(2) }}</td>
								<td style="color: rgb(221, 56, 29);">{{ subWords.wordHyp }}</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </b-popover>
  </div>
</template>
<script>
import { createUid } from '@core/utils/index';
export default {
	components: {
		AudioPlayer: () => import('./AudioPlayer'),
		LetterComponent: () => import('./LetterComponent')
	},
	props: {
		word: {
			type: Object,
      default: () => { return {}; },
		}
	},
	data() {
		return {
      component_id: createUid('word'),
    };
	},
	computed: {
		color() {
			let color = null;
			let score = 0;
			this.word.subWords.forEach((subWords)=>{
				if (subWords.wordScore){
					score += subWords.wordScore
				}
			})
			if (score < 60) {
				color = 'color_danger';
			} else if (score <= 80) {
				color = 'color_warning';
			} else {
				color = 'color_primary';
			}
			return color;
		}
	},
}
</script>
<style lang="scss">
.popover {
  max-width: 100% !important;
}
.hint-table {
  white-space: nowrap;
  margin: 0;
  width: 100%;
  text-align: center;

  tr:first-child th,
  tr:first-child td {
    border-top: none;
	}

	tr th {
		padding: 15px 10px;
    border-bottom: 1px solid #ededed;
	}
	tr td {
		padding: 15px 10px;
    border-top: 1px solid #ededed;
    border-left: 1px solid #ededed;
	}
}

.phone-hint {
	max-height: 350px;
	overflow-y: auto;
}

.phone-hint::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
	border-radius: 10px;
}

.phone-hint::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.phone-hint::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #ccc;
}
</style>
<style>
.color_danger {
	color: #f05454;
}
.color_primary {
	color: #0c9463;
}

.color_warning {
	color: #ffd800;
}
</style>
